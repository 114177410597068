import React from "react"
import PropTypes from "prop-types"
import Image from "../Image/Image"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import styles from "./ImageGallery.module.css"

export default function ImageGallery({ items }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [curIndex, setCurIndex] = React.useState(0)

  const nextIndex = (curIndex + 1) % items.length
  const prevIndex = (curIndex + items.length - 1) % items.length

  return (
    <>
      <ul className={styles.list}>
        {items.map((item, i) => (
          <li key={i} className={styles.listItem}>
            <button
              className={styles.listItemButton}
              onClick={() => {
                setCurIndex(i)
                setIsOpen(true)
              }}
            >
              <Image background className={styles.image} {...item} />
            </button>
          </li>
        ))}
      </ul>
      <div className={styles.lightbox}>
        {isOpen && (
          <Lightbox
            mainSrc={items[curIndex].url}
            nextSrc={items[nextIndex].url}
            prevSrc={items[prevIndex].url}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setCurIndex(prevIndex)}
            onMoveNextRequest={() => setCurIndex(nextIndex)}
          />
        )}
      </div>
    </>
  )
}

ImageGallery.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      fluid: PropTypes.object.isRequired,
    })
  ).isRequired,
}
