import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import styles from "./ProjectList.module.css"
import Title from "../Title/Title"
import Image from "../Image/Image"
import Text from "../Text/Text"
import Spacer from "../Spacer/Spacer"

import { projectPath } from "../../utils/paths"

export default function ProjectList({ items }) {
  return (
    <ul className={styles.list}>
      {items.map(
        ({ code, title, cover, url, subtitle, subtitleHighlight }, i) => (
          <li key={i} className={styles.listItem}>
            <Link to={projectPath(code, url)} className={styles.itemContainer}>
              <div className={styles.itemImageContainer}>
                <Image className={styles.itemImage} fluid={cover.fluid} />
              </div>
              <div className={styles.itemContentContainer}>
                <Title tagName="p" className={styles.itemCode}>
                  {code}
                </Title>
                <Title className={styles.itemTitle} light>
                  {title}
                </Title>
                {(subtitle || subtitleHighlight) && (
                  <>
                    <Spacer />
                    {subtitleHighlight && (
                      <Text uppercase bold semispread>
                        {subtitleHighlight}
                      </Text>
                    )}
                    {subtitle && (
                      <Text uppercase bold semispread>
                        {subtitle}
                      </Text>
                    )}
                  </>
                )}
              </div>
            </Link>
          </li>
        )
      )}
    </ul>
  )
}

ProjectList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      cover: PropTypes.shape({ fluid: PropTypes.object.isRequired }).isRequired,
      subtitle: PropTypes.string,
      subtitleHighlight: PropTypes.string,
    })
  ).isRequired,
}
