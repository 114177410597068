import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/Layout/Layout"
import Carousel from "../../components/Carousel/Carousel"
import Section from "../../components/Section/Section"
import Title from "../../components/Title/Title"
import Text from "../../components/Text/Text"
import Spacer from "../../components/Spacer/Spacer"
import styles from "./Project.module.css"
import Columns from "../../components/Columns/Columns"
import DefinitionList from "../../components/DefinitionList/DefinitionList"
import ImageGallery from "../../components/ImageGallery/ImageGallery"
import ProjectList from "../../components/ProjectList/ProjectList"

export default function ProductPage({
  data: {
    datoCmsProject: {
      seoDescription,
      code,
      title,
      subtitleHighlight,
      subtitle,
      subtitleExtended,
      cover,
      memorial,
      metadata,
      gallery,
      date,
      relatedProjects,
    },
  },
}) {
  const validSubtitle = subtitleExtended || subtitle
  const memorialItems = (memorial || []).map(o => (
    <Text html={o.contentNode.childMarkdownRemark.html} />
  ))
  const validRelatedProjects = relatedProjects.filter(o => o.code !== code)

  return (
    <Layout
      seoTitle={`${code} - ${title}`}
      seoDescription={seoDescription}
      footerDivider
    >
      <Carousel items={[{ image: cover }]} />
      <Section>
        <div className={styles.headerContainer}>
          <div>
            <Title inline tagName="p">
              {code}
            </Title>
            <div className={styles.headerTitleSpacer} />
            <Title inline light>
              {title}
            </Title>
            {validSubtitle && (
              <>
                <Spacer small />
                <Text uppercase semispread>
                  {validSubtitle}
                </Text>
              </>
            )}
          </div>
          <div className={styles.headerTimeContainer}>
            <Title tagName="time">{date}</Title>
            {subtitleHighlight && (
              <>
                <Spacer small />
                <Text uppercase semispread bold>
                  {subtitleHighlight}
                </Text>
              </>
            )}
          </div>
        </div>
      </Section>

      <Section>
        <Columns items={memorialItems} />
        <Spacer />
        <Spacer />
        <ImageGallery items={gallery} />
        <Spacer />
        <div className={styles.definitionList}>
          <DefinitionList
            wrapped
            items={[
              {
                label: "Projeto:",
                value: [code, title].join(" - "),
              },
            ].concat(
              metadata.map(({ title, body }) => ({
                label: `${title}:`,
                value: body,
              }))
            )}
          />
        </div>
      </Section>

      {validRelatedProjects.length > 0 && (
        <Section>
          <Title>Projetos relacionados</Title>
          <Spacer />
          <ProjectList items={validRelatedProjects} />
        </Section>
      )}

      <Spacer />
    </Layout>
  )
}

export const query = graphql`
  query ProjectPage($id: String!) {
    datoCmsProject(id: { eq: $id }) {
      seoDescription
      code
      title
      subtitleHighlight
      subtitle
      subtitleExtended
      date(formatString: "YYYY")
      memorial {
        ... on DatoCmsColumn {
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      metadata {
        ... on DatoCmsMetadataItem {
          title
          body
        }
      }
      gallery {
        url
        fluid(maxWidth: 230, imgixParams: { fm: "webp", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      cover {
        fluid(maxWidth: 960, imgixParams: { fm: "webp", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      relatedProjects {
        date(formatString: "YYYY")
        code
        title
        subtitleHighlight
        subtitle
        url
        cover {
          fluid(maxWidth: 960, imgixParams: { fm: "webp", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        tags {
          title
        }
      }
    }
  }
`
